import React, { createContext, useContext, useState, useEffect } from 'react'
import { onAuthStateChanged, onIdTokenChanged } from 'actions/firebase/auth'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ auth: null, idToken: null })
  const [loading, setLoading] = useState(true)
  const [cleanup, setCleanUp] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(async (user) => {
      if (!user) {
        setLoading(false)
      }
    })
    const unsubscribe1 = onIdTokenChanged(async (user) => {
      if (!cleanup && user) {
        const idToken = await user.getIdTokenResult(true)
        setAuth({ auth: user, idToken })
        setLoading(false)
      } else if (cleanup) {
        setLoading(false)
      }
    })

    return () => {
      unsubscribe()
      unsubscribe1()
      setCleanUp(true)
    }
  }, [])

  return <AuthContext.Provider value={{ ...auth, loading }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const { auth, loading, idToken } = useContext(AuthContext)
  return [loading, auth, idToken]
}
