import React from 'react'
import Login from 'components/Login'
import { AuthProvider } from 'hooks/useAuth'
import LoginLayout from 'components/Layout/Login'

const LoginPage = (props) => (
  <LoginLayout title="Login">
    <AuthProvider>
      <Login {...props} />
    </AuthProvider>
  </LoginLayout>
)
export default LoginPage
