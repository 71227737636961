import React, { useEffect, useState } from 'react'
import { Typography, Fade } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import { LoadingButton, Button } from 'components/CustomButtons'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from './validation'
import { adminLogin } from 'actions/login'
import { navigate } from 'gatsby'
import { useAuth } from 'hooks/useAuth'
import useFirebaseRecaptcha from 'hooks/useFirebaseRecaptcha'
import useStyles from './styles'

const Login = ({ location: { search } }) => {
  const classes = useStyles()
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues: { email: '', password: '' } })

  const [loading, , idToken] = useAuth()
  const [error, setError] = useState(null)
  const { ref, verified } = useFirebaseRecaptcha()

  useEffect(() => {
    if (idToken) {
      if (idToken.claims?.permissions) {
        afterLogin()
      } else {
        setError('Wrong user or password!')
      }
    }
  }, [idToken])

  const onSubmitForm = async ({ email, password }) => {
    if (verified) {
      try {
        await adminLogin(email, password)
      } catch (e) {
        setError(e.message)
      }
    }
  }

  const afterLogin = () => {
    navigate(new URLSearchParams(search).get('ret') ?? '/admin')
  }

  const onForget = () => {
    navigate('/forget')
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Typography variant="h3" className={classes.greeting}>
        Sign in
      </Typography>
      <Fade in={!!error}>
        <Typography color="secondary" className={classes.errorMessage}>
          {error}
        </Typography>
      </Fade>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <CustomInput
            {...field}
            type="email"
            label="Email Address"
            error={errors.email}
            style={{ marginTop: '10px' }}
            disabled={loading}
            fullWidth
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <CustomInput
            {...field}
            type="password"
            label="Password"
            error={errors.password}
            style={{ marginTop: '10px' }}
            disabled={loading}
            fullWidth
          />
        )}
      />
      <div ref={ref} />
      <div className={classes.formButtons}>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          isLoading={isSubmitting || loading}
          disabled={!isDirty || !verified}
        >
          Login
        </LoadingButton>
        <Button size="small" variant="text" className={classes.forgetButton} onClick={onForget} disabled={loading}>
          Forget Password?
        </Button>
      </div>
    </form>
  )
}

export default Login
