import React from 'react'
// material-ui components
import { Button } from '@material-ui/core'
import useStyles from './styles'

const CustomButton = ({ children, variant = 'contained', size = 'medium', ...props }) => {
  const classes = useStyles()
  return (
    <Button className={classes.button} variant={variant} size={size} {...props}>
      {children}
    </Button>
  )
}
export default CustomButton
