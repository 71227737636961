import yup from 'utils/yup'

export default yup.object({
  email: yup
    .string()
    .email()
    .label('Email')
    .required(),
  password: yup.string().required('No password provided.'),
})
