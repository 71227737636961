import React, { useEffect, useState, useRef, memo } from 'react'
import { RecaptchaVerifier } from 'actions/firebase/auth'

const useFirebaseRecaptcha = () => {
  const [recaptcha, setRecaptcha] = useState(null)
  const [verified, setVerified] = useState(null)
  const ref = useRef()

  useEffect(() => {
    const r = RecaptchaVerifier(ref.current)
    r.render()
    setRecaptcha(r)
    r.verify().then((v) => setVerified(v))

    return () => {
      r.clear()
    }
  }, [])

  return { ref, recaptcha, verified }
}

export default useFirebaseRecaptcha
