import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  button: {
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
  },
}))
