import React from 'react'
import { Grid } from '@material-ui/core'
import Logo from 'components/Logo/large'
import useStyles from './styles'
import SEO from 'components/seo'
import { NoSsr } from '@material-ui/core'

const LoginLayout = ({ children, title = 'Home' }) => {
  const classes = useStyles()

  return (
    <NoSsr>
      <Grid container className={classes.container}>
        <SEO title={title} />
        <div className={classes.logotypeContainer}>
          <div className={classes.logotypeImage}>
            <Logo />
          </div>
          <div className={classes.logotypeText}>Getslot</div>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.form}>{children}</div>
        </div>
      </Grid>
    </NoSsr>
  )
}

export default LoginLayout
